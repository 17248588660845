/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useDeleteUser, useListPeople } from "../../api/users";
import RedirectLink from "../../components/RedirectLink";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import utilColors from "../../styles/utilColors";
import { adaptUsers } from "../../adapters/users";
import { convertUTCToLocalDateTime } from "../../utils/dateUtils";
import useIamModal from "../IamModal/UseIamModal";
import { useAddUserDialog } from "./dialog/DialogHooks";
import { useHasPermission } from "../../api/iam";

const People = ({ displayNotification, setConfirmationDialog }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { IamModal, IamButton } = useIamModal("/user-management/users");

  const [people, setPeople] = useState([]);

  const {
    postData: listPeople,
    response: listPeopleResp,
    isLoading: isLoadingPrs,
  } = useListPeople();

  const {
    postData: deleteUser,
    response: deleteUserResp,
    responseStatus: deleteUserStatus,
  } = useDeleteUser();

  const { addUserDialog, setOpenAddUserDialog } = useAddUserDialog(
    listPeople,
    displayNotification
  );

  useEffect(() => {
    listPeople();
  }, []);

  useEffect(() => {
    if (Array.isArray(listPeopleResp?.users)) {
      setPeople(adaptUsers(listPeopleResp.users));
    }
  }, [listPeopleResp]);

  useEffect(() => {
    displayNotification(deleteUserResp, deleteUserStatus, () => {
      listPeople();
    });
  }, [deleteUserResp, deleteUserStatus]);

  const handleDelete = (user) => {
    setConfirmationDialog({
      isOpen: true,
      title: "Delete User",
      content: `You are going to delete the user: "${user?.emailId}"`,
      handleDialogConfirm: () => {
        deleteUser(`/${user?.id}`);
      },
    });
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 2,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { name } }) => (
        <Typography variant="h5">{name || ""}</Typography>
      ),
    },
    {
      field: "githubId",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Github</Typography>,
      renderCell: ({ row: { githubId } }) => (
        <RedirectLink
          href={`https://github.com/${githubId}`}
          color={utilColors.skyBlue}
        >
          <Typography variant="h5">{githubId ?? ""}</Typography>
        </RedirectLink>
      ),
    },
    {
      field: "emailId",
      flex: 2.5,
      renderHeader: () => <Typography variant="h5">Email</Typography>,
      renderCell: ({ row: { emailId } }) => (
        <Typography variant="h5">{emailId ?? ""}</Typography>
      ),
    },
    {
      field: "managerEmailId",
      flex: 2.5,
      renderHeader: () => <Typography variant="h5">Manager</Typography>,
      renderCell: ({ row: { managerEmailId } }) => (
        <Typography variant="h5">{managerEmailId ?? ""}</Typography>
      ),
    },
    {
      field: "createdOn",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Created On</Typography>,
      renderCell: ({ row: { createdOn } }) => (
        <Typography variant="h5">
          {convertUTCToLocalDateTime(createdOn)}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: ({ row }) =>
        [
          <GridActionsCellItem
            label="Delete"
            onClick={() => handleDelete(row)}
            showInMenu
          />,
        ].filter(Boolean),
    },
  ];

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      {IamModal}
      {addUserDialog}
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid item xs={1}>
          <Header
            title="People"
            subtitle="list of people registered in convoy"
          />
        </Grid>
        <Grid item xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            {IamButton}
            {useHasPermission("/user-management/users", "POST", "ADD") ? (
              <DataGridButton
                sx={dataGridButtonStyles.pageTopButtons}
                onClick={setOpenAddUserDialog}
              >
                <Typography sx={dataGridButtonTypography(colors, 500)}>
                  ADD
                </Typography>
              </DataGridButton>
            ) : (
              <></>
            )}
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => listPeople()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.id}
          loading={isLoadingPrs}
          rows={people}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default People;
