/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { toCamelCase } from "../../../utils/helperFunctions";
import { createDialogStyles as styles } from "../styles";
import ConvoyDialog from "../../../components/ConvoyDialog";
import { useCreateBot } from "../../../api/users";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";

const CreateBotDialog = ({
  open,
  resetDialog,
  displayNotification,
  callback,
}) => {
  const [payload, setPayload] = useState({});
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [showKey, setShowKey] = useState(false);

  const {
    postData: createBot,
    response: createBotResp,
    responseStatus: createBotStatus,
  } = useCreateBot();

  useEffect(() => {
    if (!open) {
      setPayload({});
      setRequestSubmitted(false);
      setShowKey(false);
    }
  }, [open]);

  useEffect(() => {
    if (createBotStatus === 201) {
      setRequestSubmitted(true);
    }
  }, [createBotResp]);

  useEffect(() => {
    displayNotification(createBotResp, createBotStatus, callback);
  }, [createBotResp, createBotStatus]);

  const handleDialogConfirm = () => {
    if (requestSubmitted) {
      resetDialog();
    } else {
      createBot(payload);
      setRequestSubmitted(true);
    }
  };

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: payload?.[toCamelCase(label)] || "",
    onChange: (e) => {
      setPayload((prev) => ({
        ...prev,
        [toCamelCase(label)]: e.target.value,
      }));
    },
  });

  return (
    <ConvoyDialog
      open={open}
      title={`Add user`}
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={resetDialog}
    >
      {requestSubmitted ? (
        <Grid container spacing={2} sx={styles.inputGridContainer}>
          <Grid item xs={12}>
            <Typography variant="h5">
              API key is available only once and cannot be retrived again. Copy
              and store it.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email Id"
              color="secondary"
              value={createBotResp?.emailId || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel color="secondary" htmlFor="api-key">
                API Key
              </InputLabel>
              <OutlinedInput
                id="api-key"
                label=" API Key"
                color="secondary"
                value={createBotResp?.apiKey || ""}
                type={showKey ? "text" : "password"}
                InputProps={{
                  readOnly: true,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy key"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(createBotResp?.apiKey);
                      }}
                      edge="end"
                    >
                      <ContentCopy />
                    </IconButton>
                    <IconButton
                      aria-label="toggle visibility"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowKey(!showKey);
                      }}
                      edge="end"
                    >
                      {showKey ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={styles.inputGridContainer}>
          <Grid item xs={4}>
            <TextField {...textFieldCommonProps("Name")} />
          </Grid>
          <Grid item xs={8}>
            <TextField {...textFieldCommonProps("Description")} />
          </Grid>
        </Grid>
      )}
    </ConvoyDialog>
  );
};

export default CreateBotDialog;
