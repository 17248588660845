/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { toCamelCase } from "../../../../utils/helperFunctions";
import { createDialogStyles as styles } from "../styles";
import ConvoyDialog from "../../../../components/ConvoyDialog";
import { TextFieldWithHelperText } from "../../../../components/TextFieldWithHelperText";
import { useCreateGroup, useCreateRole } from "../../../../api/iam";
import { IAM_ENTITY } from "../../../../utils/constants";

const useCreateEntity = (entity, path) => {
  const groupObj = useCreateGroup(path);
  const roleObj = useCreateRole(path);
  switch (entity) {
    case IAM_ENTITY.GROUP:
      return groupObj;
    case IAM_ENTITY.ROLE:
      return roleObj;
    default:
      return {};
  }
};

const CreateDialog = ({
  dialogProps,
  setDialogProps,
  path,
  showNotificationWrapper,
}) => {
  const [payload, setPayload] = useState({});
  const [dialogPropsCopy, setDialogPropsCopy] = useState({});

  useEffect(() => {
    if (dialogProps?.entity) {
      setDialogPropsCopy(dialogProps);
    }
  }, [dialogProps]);

  const {
    postData: createEntity,
    response: createEntityResp,
    responseStatus: createEntityStatus,
  } = useCreateEntity(dialogPropsCopy?.entity, path);

  useEffect(() => {
    if (!dialogProps?.isOpen) {
      setPayload({});
    }
  }, [dialogProps]);

  useEffect(() => {
    showNotificationWrapper(
      createEntityResp,
      createEntityStatus,
      dialogPropsCopy?.callback
    );
  }, [createEntityResp, createEntityStatus]);

  const resetDialog = () => {
    setDialogProps({
      isOpen: false,
      entity: "",
    });
  };

  const handleDialogConfirm = () => {
    createEntity(payload);
    resetDialog();
  };

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: payload?.[toCamelCase(label)] || "",
    onChange: (e) => {
      setPayload((prev) => ({
        ...prev,
        [toCamelCase(label)]: e.target.value,
      }));
    },
  });

  return (
    <ConvoyDialog
      open={dialogProps?.isOpen}
      title={`Create ${dialogProps?.entity}`}
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={resetDialog}
      disabled={!(payload?.name && payload?.description)}
    >
      <Grid container spacing={2} sx={styles.summaryInputGridContainer}>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Name")}
            onChange={(e) => {
              /^[A-Z,a-z,0-9,\-,_]*$/.test(e.target.value) &&
                setPayload((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
            }}
            helperText="alphanumerical, hyphen and underscores only"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField {...textFieldCommonProps("Description")} />
        </Grid>
        {dialogProps?.entity === IAM_ENTITY.ROLE ? (
          <Grid item xs={12}>
            <TextFieldWithHelperText
              defaultValue={path}
              helperText="Must have current path as prefix"
              {...textFieldCommonProps("forPath")}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </ConvoyDialog>
  );
};

export default CreateDialog;
