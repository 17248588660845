/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetAutomationRuns } from "../../api/automationRuns";
import DataGridButton from "../../components/DataGridButton";
import * as React from "react";
import { colors as muiColors } from "@mui/material";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import { INFINITE_SCROLLING_PAGE_SIZE } from "../../utils/constants";
import { adaptAutomationRuns, getDeploymentStage } from "./utils";
import { qaosUrl } from "../../utils/urls";
import { useReportModal } from "../../components/ReportModal";
import useTriggerAutomationDialog from "./TriggerAutomationDialog";
import { showNotification } from "../../utils/helperFunctions";
import Notification from "../../components/Notification";
import { styles } from "./styles";

const AutomationRuns = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { tenantUrl } = useParams();

  const [automationRuns, setAutomationRuns] = useState({ page: 0, runs: [] });
  const [currPage, setCurrPage] = useState(1);

  const { reportModal, setReportModalOpen } = useReportModal();

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const notificationStates = {
    setNotify: setNotify,
    setMessage: setMessage,
    setSeverity: setSeverity,
  };

  const showNotificationWrapper = (resp, respStatus, callback = () => {}) => {
    showNotification(
      resp,
      respStatus,
      callback,
      notificationStates.setMessage,
      notificationStates.setSeverity,
      notificationStates.setNotify
    );
  };

  const {
    postData: listAllAutomationRuns,
    response: listAllAutomationRunsResp,
    isLoading: isLoadingAutomationRuns,
  } = useGetAutomationRuns(getDeploymentStage(tenantUrl), tenantUrl);

  const listAllAutomationRunsWrapper = (pageNo) => {
    listAllAutomationRuns(
      `?pageSize=${INFINITE_SCROLLING_PAGE_SIZE}&page=${pageNo}`
    );
  };

  const { triggerAutomationDialog, setTriggerAutomationDialogOpen } =
    useTriggerAutomationDialog(
      getDeploymentStage(tenantUrl),
      tenantUrl,
      showNotificationWrapper,
      () => {
        if (currPage !== 1) {
          setAutomationRuns({ page: 0, runs: [] });
          setCurrPage(1);
        } else {
          listAllAutomationRunsWrapper(currPage);
        }
      }
    );

  useEffect(() => {
    listAllAutomationRunsWrapper(currPage);
  }, [currPage]);

  const handleShowReport = (id) => {
    const depStage = getDeploymentStage(tenantUrl);
    setReportModalOpen(
      `${qaosUrl}/tessell/${depStage}/tenants/${tenantUrl}/automation-runs/${id}/report`
    );
  };

  useEffect(() => {
    if (Array.isArray(listAllAutomationRunsResp?.automationRuns)) {
      setAutomationRuns((prev) => {
        if (currPage !== prev?.page + 1) {
          return prev;
        }
        const newRuns = adaptAutomationRuns(
          listAllAutomationRunsResp?.automationRuns
        );
        return {
          page: prev?.page + 1,
          runs: prev?.page ? [...(prev?.runs || []), ...newRuns] : newRuns,
        };
      });
    }
  }, [listAllAutomationRunsResp]);

  const columns = [
    {
      field: "workflowSuffix",
      flex: 1,
      renderHeader: () => (
        <Typography paddingLeft="15px" variant="h5">
          Workflow
        </Typography>
      ),
      renderCell: ({ row: { workflowSuffix } }) => (
        <Typography variant="h5" paddingLeft="15px">
          {workflowSuffix || ""}
        </Typography>
      ),
    },
    {
      renderHeader: () => <Typography variant="h5">Report</Typography>,
      headerAlign: "center",
      flex: 1.5,
      renderCell: ({ row: { id, status } }) => (
        <DataGridButton
          sx={dataGridButtonStyles.refreshButton}
          onClick={() => handleShowReport(id)}
          disabled={status !== "COMPLETED"}
        >
          <Typography sx={dataGridButtonTypography(colors)}>
            View Report
          </Typography>
        </DataGridButton>
      ),
    },
    {
      field: "result",
      renderHeader: () => <Typography variant="h5">Result</Typography>,
      headerAlign: "center",
      flex: 2,
      renderCell: ({ row: { compliedResults } }) => (
        <Box sx={styles.resultStack}>
          <Typography
            textAlign={"right"}
            variant="h5"
            color={muiColors.green[400]}
          >
            {compliedResults?.passed ?? "-"}
          </Typography>
          /
          <Typography
            textAlign={"right"}
            variant="h5"
            color={muiColors.red[300]}
          >
            {compliedResults?.failed ?? "-"}
          </Typography>
          /
          <Typography textAlign={"right"} variant="h5">
            {compliedResults?.skipped ?? "-"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      flex: 1.5,
      renderCell: ({ row: { status } }) => (
        <Typography variant="h5">{status || ""}</Typography>
      ),
    },

    {
      field: "startTime",
      renderHeader: () => <Typography variant="h5">Start Time</Typography>,
      flex: 2,
      renderCell: ({ row: { startTime } }) => (
        <Typography variant="h5">{startTime || ""}</Typography>
      ),
    },
    {
      field: "endTime",
      renderHeader: () => <Typography variant="h5">End Time</Typography>,
      flex: 2,
      renderCell: ({ row: { endTime } }) => (
        <Typography variant="h5">{endTime || ""}</Typography>
      ),
    },
    {
      field: "submittedBy",
      renderHeader: () => <Typography variant="h5">Submitted By</Typography>,
      flex: 2,
      renderCell: ({ row: { submittedBy } }) => (
        <Typography variant="h5">
          {submittedBy.split("@")?.[0] || ""}
        </Typography>
      ),
    },
  ];
  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      {reportModal}
      {triggerAutomationDialog}
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid xs={1}>
          <Header
            title="Automation Runs"
            subtitle={`View the automation runs for ${
              tenantUrl?.split(".")[0]
            }`}
          />
        </Grid>
        <Grid xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => {
                if (currPage !== 1) {
                  setAutomationRuns({ page: 0, runs: [] });
                  setCurrPage(1);
                } else {
                  listAllAutomationRunsWrapper(currPage);
                }
              }}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={setTriggerAutomationDialogOpen}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                SUBMIT NEW
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          onRowsScrollEnd={() => {
            setCurrPage(automationRuns?.page + 1);
          }}
          scrollEndThreshold={1}
          loading={isLoadingAutomationRuns}
          rows={automationRuns?.runs}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default AutomationRuns;
