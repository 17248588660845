/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { ControlPointRounded } from "@mui/icons-material";
import { useListBots, useListPeople } from "../../../../api/users";
import { assigneesDialogStyles as styles } from "../styles";
import AssigneeItem from "./AssigneeItem";

const UsersList = ({ currUsers, setCurrUsers, canUpdateAssignees }) => {
  const [newUser, setNewUser] = useState("");

  const { postData: listPoeple, response: listPeopleResp } = useListPeople();
  const { postData: listBots, response: listBotsResp } = useListBots();

  const allUsers = useMemo(() => {
    return [
      ...(listPeopleResp?.users || []),
      ...(listBotsResp?.users || []),
    ].map((user) => {
      return user?.emailId || "";
    });
  }, [listPeopleResp, listBotsResp]);

  useEffect(() => {
    listPoeple();
    listBots();
  }, []);

  const handleAddUser = (e) => {
    if (!allUsers.includes(newUser)) {
      return;
    }

    setCurrUsers((prev) => {
      if (prev.includes(newUser)) {
        return prev;
      }
      return [...prev, newUser];
    });
    setNewUser("");
  };

  const deleteUser = (userToDelete) => {
    setCurrUsers((prev) => prev.filter((user) => user !== userToDelete));
  };

  return (
    <Box sx={styles.assigneeTypeStack}>
      <Typography variant="h5">Users</Typography>
      <Divider />
      <Virtuoso
        data={currUsers}
        components={AssigneeItem}
        itemContent={(index, userEmail) => (
          <AssigneeItem
            assignee={userEmail}
            deleteAssignee={deleteUser}
            canUpdateAssignees={canUpdateAssignees}
          />
        )}
      />
      {canUpdateAssignees ? (
        <Box sx={styles.autocompleteStack}>
          <Autocomplete
            noOptionsText="No Users available"
            label="Users"
            size="small"
            value={newUser || ""}
            fullWidth
            options={allUsers}
            onChange={(event, newValue) => {
              event.preventDefault();
              event.stopPropagation();
              setNewUser(newValue);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select User"
                hiddenLabel
                size="small"
              />
            )}
          />
          <IconButton flex="1" onClick={(event) => handleAddUser(event)}>
            <ControlPointRounded />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default UsersList;
