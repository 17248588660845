/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import DataGridButton from "../../../components/DataGridButton";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { tokens } from "../../../theme";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { commonPageStyles } from "../styles";
import { useDeleteGroup, useListAllGroups } from "../../../api/iam";
import { convertUTCToLocalDateTime } from "../../../utils/dateUtils";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { IAM_ENTITY } from "../../../utils/constants";
import { stackCenterGap } from "../../../styles/utilStyles";

const Groups = ({
  path,
  setCreateDialogProps,
  setAssigneesDialogProps,
  showNotificationWrapper,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [groups, setGroups] = useState([]);

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const {
    postData: listAllGroups,
    response: listAllGroupsResp,
    isLoading: isLoadingAllGroups,
  } = useListAllGroups(path);

  const {
    postData: deleteGroup,
    response: deleteGroupResp,
    responseStatus: deleteGroupStatus,
  } = useDeleteGroup(path);

  useEffect(() => {
    if (Array.isArray(listAllGroupsResp?.groups)) {
      setGroups(listAllGroupsResp.groups);
    }
  }, [listAllGroupsResp]);

  useEffect(() => {
    listAllGroups();
  }, []);

  useEffect(() => {
    showNotificationWrapper(deleteGroupResp, deleteGroupStatus, () => {
      listAllGroups();
    });
  }, [deleteGroupResp, deleteGroupStatus]);

  const handleDelete = (group) => {
    const groupName = group?.name;
    setConfirmationDialog({
      isOpen: true,
      title: "Delete Group",
      content: `You are going to delete the group "${groupName}". Are you sure?`,
      handleDialogConfirm: () => {
        deleteGroup(`/${groupName}`);
      },
    });
  };

  const handleMembers = (group) => {
    const groupName = group?.name;
    setAssigneesDialogProps({
      isOpen: true,
      entity: IAM_ENTITY.GROUP,
      entityName: groupName,
    });
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { name } }) => (
        <Typography variant="h5">{name || ""}</Typography>
      ),
    },
    {
      field: "createdOn",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Created On</Typography>,
      renderCell: ({ row: { createdOn } }) => (
        <Typography variant="h5">
          {convertUTCToLocalDateTime(createdOn) ?? ""}
        </Typography>
      ),
    },
    {
      field: "createdBy",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Created By</Typography>,
      renderCell: ({ row: { createdBy } }) => (
        <Typography variant="h5">{createdBy ?? ""}</Typography>
      ),
    },
    {
      field: "description",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Description</Typography>,
      renderCell: ({ row: { description } }) => (
        <Typography variant="h5">{description}</Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: ({ row }) =>
        [
          <GridActionsCellItem
            label="Members"
            onClick={() => handleMembers(row)}
            showInMenu
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={() => handleDelete(row)}
            showInMenu
          />,
        ].filter(Boolean),
    },
  ].filter(Boolean);

  return (
    <Box sx={commonPageStyles.tabContentMainBox}>
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Box sx={commonPageStyles.topButtonStack}>
        <DataGridButton
          sx={commonPageStyles.pageTopButtons}
          onClick={() =>
            setCreateDialogProps({
              isOpen: true,
              entity: IAM_ENTITY.GROUP,
              callback: listAllGroups,
            })
          }
        >
          <Typography sx={dataGridButtonTypography(colors, 500)}>
            CREATE
          </Typography>
        </DataGridButton>
        <DataGridButton
          sx={commonPageStyles.pageTopButtons}
          onClick={() => listAllGroups()}
        >
          <Typography sx={dataGridButtonTypography(colors, 500)}>
            RELOAD
          </Typography>
        </DataGridButton>
        <Box sx={stackCenterGap} />
        <Typography variant="h5" sx={commonPageStyles.pathTypography(colors)}>
          Path: {path}
        </Typography>
      </Box>
      <Box sx={commonPageStyles.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.id}
          loading={isLoadingAllGroups}
          rows={groups}
          columns={columns}
          slots={{
            loadingOverlay: LoadingOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default Groups;
