import { commonDataGridStyles } from "../../styles/DataGridStyles";
import { alpha } from "@mui/material";

export const detailPanelContentSyles = {
  gridContainerSyles: { py: 2, height: "100%", boxSizing: "border-box" },
  paperStyles: (colors) => ({
    flex: 1,
    mx: "auto",
    width: "99%",
    p: 1,
    height: "100%",
    border: `1px solid ${colors.grey[400]}`,
  }),
  metadataTextFieldInputProps: {
    readOnly: true,
    style: { fontSize: 12, fontFamily: "Courier" },
    disableUnderline: true,
  },
  metadataTextFieldSx: { m: "5px", width: "100%" },
  textBlockHeadingTypoStyle: { mt: "15px", fontWeight: "700" },
};

export const mainModalStyles = {
  mainBox: (colors) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "80vh",
    border: 2,
    borderColor: alpha(colors.green[200], 0.25),
    borderRadius: 2,
    bgcolor: colors.primary[800],
  }),
  tabListBox: {
    borderBottom: 1,
    borderColor: "divider",
    width: "100%",
    mb: "-20px",
  },
  tabList: {
    height: "50px",
  },
  tab: {
    pb: "30px",
  },
};

export const commonPageStyles = {
  tabContentMainBox: {
    mx: "-24px",
  },
  topButtonStack: {
    mr: "10px",
    mb: "5px",
    display: "flex",
    flexDirection: "row-reverse",
  },
  pageTopButtons: {
    m: "0px 0 0 2px",
    width: "150px",
  },
  pathTypography: (colors) => ({
    mt: "5px",
    ml: "10px",
    color: colors.green[500],
  }),
  dataGridBoxStyles: (colors) => ({
    ...commonDataGridStyles(colors),
    height: "calc( 80vh - 110px)",
    width: "99.4%",
  }),
};
