/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { ControlPointRounded } from "@mui/icons-material";
import { assigneesDialogStyles as styles } from "../styles";
import { useListAllGroups } from "../../../../api/iam";
import AssigneeItem from "./AssigneeItem";

const GroupsList = ({
  currGroupIds,
  setCurrGroupIds,
  path,
  canUpdateAssignees,
}) => {
  const [selectedGroupFaceValue, setSelectedGroupFaceValue] = useState("");

  const { postData: listAllGroups, response: listAllGroupsResp } =
    useListAllGroups(path);
  const { postData: listAllGroups2, response: listAllGroupsResp2 } =
    useListAllGroups(path);

  useEffect(() => {
    listAllGroups();
    listAllGroups2("?level=PARENT");
  }, []);

  const getFaceValueOfGroup = (group) => {
    return (
      `${group?.name}` +
      (group?.createdAt !== path ? ` (${group?.createdAt || ""})` : "")
    );
  };

  const allGroupIdToFaceValue = useMemo(() => {
    return [
      ...(listAllGroupsResp?.groups || []),
      ...(listAllGroupsResp2?.groups || []),
    ].reduce((accum, curr) => {
      const nxt = curr?.id
        ? {
            ...accum,
            [curr.id]: getFaceValueOfGroup(curr),
          }
        : accum;
      return nxt;
    }, {});
  }, [listAllGroupsResp, listAllGroupsResp2]);

  const allGroupFaceValueToId = useMemo(() => {
    return [
      ...(listAllGroupsResp?.groups || []),
      ...(listAllGroupsResp2?.groups || []),
    ].reduce((accum, curr) => {
      return curr?.id
        ? {
            ...accum,
            [getFaceValueOfGroup(curr)]: curr.id,
          }
        : accum;
    }, {});
  });

  const handleAddGroup = (e) => {
    const selectedGroupId = allGroupFaceValueToId?.[selectedGroupFaceValue];
    if (selectedGroupId === undefined) {
      return;
    }
    setCurrGroupIds((prev) => {
      if (prev.includes(selectedGroupId)) {
        return prev;
      }
      return [...prev, selectedGroupId];
    });
    setSelectedGroupFaceValue("");
  };

  const deleteGroup = (faceValue) => {
    setCurrGroupIds((prev) =>
      prev.filter((groupId) => groupId !== allGroupFaceValueToId?.[faceValue])
    );
  };

  return (
    <Box sx={styles.assigneeTypeStack}>
      <Typography variant="h5">Groups</Typography>
      <Divider />
      <Virtuoso
        data={currGroupIds}
        components={AssigneeItem}
        itemContent={(index, groupId) => (
          <AssigneeItem
            assignee={allGroupIdToFaceValue?.[groupId]}
            deleteAssignee={deleteGroup}
            canUpdateAssignees={canUpdateAssignees}
          />
        )}
      />
      {canUpdateAssignees ? (
        <Box sx={styles.autocompleteStack}>
          <Autocomplete
            noOptionsText="No Groups available"
            label="Groups"
            size="small"
            value={selectedGroupFaceValue || ""}
            fullWidth
            options={Object.keys(allGroupFaceValueToId)}
            onChange={(event, newValue) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedGroupFaceValue(newValue);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Assignee"
                hiddenLabel
                size="small"
              />
            )}
          />
          <IconButton flex="1" onClick={(event) => handleAddGroup(event)}>
            <ControlPointRounded />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default GroupsList;
