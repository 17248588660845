import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import ControlPlanes from "./ControlPlanes";

const TessellDeployments = () => {
  const [selectedTab, setSelectedTab] = useState("Control Planes");
  const tabs = [
    {
      value: "Control Planes",
      component: <ControlPlanes />,
    },
  ];
  return (
    <Box sx={tabbedDataGridPage.mainBox}>
      <Box sx={tabbedDataGridPage.tabContainer}>
        <TabContext value={selectedTab}>
          <Box sx={tabbedDataGridPage.tabListBox}>
            <TabList
              onChange={(e, newValue) => setSelectedTab(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              sx={tabbedDataGridPage.tabList}
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={i}
                  label={tab.value}
                  value={tab.value}
                  icon={<></>}
                  iconPosition="end"
                />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, i) => (
            <TabPanel key={i} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
};

export default TessellDeployments;
