/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Typography } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { permissionsDialogStyles as styles } from "../styles";

const getPath = (perm) => {
  const perfix = ".";
  const forAllSubPathsStr = perm?.forAllSubPaths ? "/*" : "";
  const iamPath = perm?.iam?.path || "";
  const forAllIamSubPathsStr = perm?.iam?.forAllSubPaths ? "/*" : "";

  return perfix + forAllSubPathsStr + iamPath + forAllIamSubPathsStr;
};

const PermissionItem = ({ perm, deletePermission, canUpdatePermissions }) => {
  const handleDeletePermission = (event) => {
    deletePermission(perm);
  };

  return (
    <Box sx={styles.permissionItemMainBox}>
      <Typography variant="h5" sx={styles.methodField}>
        {perm?.method}
      </Typography>
      <Typography variant="h5" sx={styles.actionField}>
        {perm?.action}
      </Typography>
      <Typography variant="h5" sx={styles.renderedPath}>
        {getPath(perm)}
      </Typography>

      <IconButton
        flex="1"
        onClick={(event) => handleDeletePermission(event)}
        disabled={!canUpdatePermissions}
      >
        <HighlightOffRoundedIcon />
      </IconButton>
    </Box>
  );
};

export default PermissionItem;
