/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Typography } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { assigneesDialogStyles as styles } from "../styles";

const AssigneeItem = ({ assignee, deleteAssignee, canUpdateAssignees }) => {
  return (
    <Box sx={styles.assigneeTypeItemMainBox}>
      <Typography variant="h6" sx={styles.assigneeItemTextField}>
        {assignee}
      </Typography>
      <IconButton
        flex="1"
        onClick={() => deleteAssignee(assignee)}
        disabled={!canUpdateAssignees}
      >
        <HighlightOffRoundedIcon />
      </IconButton>
    </Box>
  );
};

export default AssigneeItem;
