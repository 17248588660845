/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import moment from "moment";

import { CssBaseline, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { ColorModeContext, useMode } from "./theme";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Environments from "./pages/Environments";
import Releases from "./pages/Releases";
import Requests from "./pages/Requests";
import Argo from "./pages/Argo";
import Automations from "./pages/Automations";
import CodeCoverage from "./pages/CodeCoverage";
import PullRequests from "./pages/PullRequests";
import Cache from "./utils/Cache";
import OktaLogin from "./pages/OktaLogin";
import LoginCallback from "./pages/OktaLogin/LoginCallback";
import { useGetLoggedInUser } from "./api/global";
import { extendToken, useIsTokenValid } from "./api/auth";
import { UserContext } from "./contexts";
import TessellDeployments from "./pages/TessellDeployments";
import QaDashboard from "./pages/QA";
import UiProxy from "./pages/UiProxy";
import Users from "./pages/Users";
import Vulnerabilities from "./pages/Vulnerabilities";

const isTokenExpired = (tokenExpiry) => {
  const currTime = Date.now() / 1000;
  return (
    moment(tokenExpiry, "X", true).isValid() &&
    currTime + 300 >= tokenExpiry &&
    currTime - 43200 < tokenExpiry
  );
};

function App() {
  const [theme, colorMode] = useMode();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [email, setEmail] = useState(Cache.get("email"));

  const {
    postData: validateToken,
    response: validateTokenResp,
    isLoading: isTokenBeingValidated,
  } = useIsTokenValid();

  const { postData: getLoggedInUser, response: getLoggedInUserResp } =
    useGetLoggedInUser();

  useEffect(() => {
    const interval = setInterval(() => {
      getLoggedInUser();
    }, 30000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getLoggedInUser();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (window.location.pathname !== "/login/callback") {
      const tokenExpiry = Cache.get("tokenExpiry");
      if (!isTokenExpired(tokenExpiry)) {
        validateToken({});
      } else {
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (!isTokenBeingValidated && validateTokenResp !== null) {
      setIsLoggedIn(validateTokenResp.valid);
    }
  }, [isTokenBeingValidated, validateTokenResp]);

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenExpiry = Cache.get("tokenExpiry");
      if (isTokenExpired(tokenExpiry)) {
        clearInterval(interval);
        Cache.clear();
        setIsLoggedIn(false);
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenExpiry = Cache.get("tokenExpiry");
      if (
        moment(tokenExpiry, "X", true).isValid() &&
        tokenExpiry - (Date.now() / 1000 + 2) <= 5
      ) {
        extendToken()
          .then((resp) => resp.json())
          .then((data) => Cache.set("tokenExpiry", data.newExpiry));
      }
    }, 2000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <UserContext.Provider
        value={{
          email: email,
          data: getLoggedInUserResp,
          refresh: getLoggedInUser,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar />
            {isLoggedIn === null ? (
              <Box
                position="absolute"
                justifyContent="center"
                alignItems="center"
                height="100%"
                top="50%"
                right="50%"
              >
                <CircularProgress color="secondary" />
              </Box>
            ) : isLoggedIn ? (
              <main className="content">
                <Topbar />
                <Routes>
                  <Route path="/argo" element={<Argo />} />
                  <Route path="/" element={<Environments />} />
                  <Route path="/releases" element={<Releases />} />
                  <Route path="/requests" element={<Requests />} />
                  <Route path="/users" element={<Users />} />
                  <Route
                    path="/vulnerabilities"
                    element={<Vulnerabilities />}
                  />
                  <Route path="/qa" element={<QaDashboard />} />
                  <Route path="/automations" element={<Automations />} />
                  <Route path="/code-coverage" element={<CodeCoverage />} />
                  <Route path="/logs" element={<UiProxy context="logs" />} />
                  <Route path="/pull-requests" element={<PullRequests />} />
                  <Route
                    path="/tenants/:tenantUrl/automation-runs"
                    element={<Automations />}
                  />
                  <Route
                    path="/apps/tessell/deployments/:deploymentStage/control-planes"
                    element={<TessellDeployments />}
                  />
                </Routes>
              </main>
            ) : (
              <Routes>
                <Route
                  path="/login/callback"
                  element={
                    <LoginCallback
                      setIsLoggedIn={setIsLoggedIn}
                      setEmail={setEmail}
                    />
                  }
                />
                <Route path="*" element={<OktaLogin />} />
              </Routes>
            )}
          </div>
        </ThemeProvider>
      </UserContext.Provider>
    </ColorModeContext.Provider>
  );
}

export default App;
