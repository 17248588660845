/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { toCamelCase } from "../../../utils/helperFunctions";
import { createDialogStyles as styles } from "../styles";
import ConvoyDialog from "../../../components/ConvoyDialog";
import { useAddUser } from "../../../api/users";

const AddUserDialog = ({
  open,
  resetDialog,
  displayNotification,
  callback,
}) => {
  const [payload, setPayload] = useState({});

  const {
    postData: addUser,
    response: addUserResp,
    responseStatus: addUserStatus,
  } = useAddUser();

  useEffect(() => {
    if (!open) {
      setPayload({});
    }
  }, [open]);

  useEffect(() => {
    displayNotification(addUserResp, addUserStatus, callback);
  }, [addUserResp, addUserStatus]);

  const handleDialogConfirm = () => {
    addUser(payload);
    resetDialog();
  };

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: payload?.[toCamelCase(label)] || "",
    onChange: (e) => {
      setPayload((prev) => ({
        ...prev,
        [toCamelCase(label)]: e.target.value,
      }));
    },
  });

  return (
    <ConvoyDialog
      open={open}
      title={`Add user`}
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={resetDialog}
    >
      <Grid container spacing={2} sx={styles.inputGridContainer}>
        <Grid item xs={4}>
          <TextField {...textFieldCommonProps("Name")} />
        </Grid>
        <Grid item xs={4}>
          <TextField {...textFieldCommonProps("Github Id")} />
        </Grid>
        <Grid item xs={4}>
          <TextField {...textFieldCommonProps("Slack Id")} />
        </Grid>
        <Grid item xs={6}>
          <TextField {...textFieldCommonProps("Email Id")} />
        </Grid>
        <Grid item xs={6}>
          <TextField {...textFieldCommonProps("Manager Email Id")} />
        </Grid>
      </Grid>
    </ConvoyDialog>
  );
};

export default AddUserDialog;
