export const createDialogStyles = {
  summaryInputGridContainer: {
    padding: "7px",
    width: "50vw",
  },
};

export const assigneesDialogStyles = {
  mainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    margin: "auto",
    width: "auto",
    height: "100%",
  },
  assigneeTypeStack: {
    display: "flex",
    flexDirection: "column",
    height: 300,
    width: 300,
  },
  autocompleteStack: {
    mt: "6px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  assigneeTypeItemMainBox: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
    mt: "3px",
  },
  assigneeItemTextField: {
    flexGrow: 4,
    borderBottom: "1px solid gray",
  },
};

export const permissionsDialogStyles = {
  mainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    margin: "auto",
    width: "auto",
    height: "100%",
  },
  permissionsStack: {
    display: "flex",
    flexDirection: "column",
    height: 500,
    width: 600,
  },
  inputRowStack: {
    mt: "6px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  permissionItemMainBox: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
    mt: "3px",
    borderBottom: "1px solid gray",
  },
  methodField: {
    width: "100px",
  },
  actionField: {
    width: "180px",
  },
  iamPathTextField: { width: "288px" },
  renderedPath: {
    flexGrow: 1,
  },
};
