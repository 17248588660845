/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginCallback } from "../../api/auth";
import Cache from "../../utils/Cache";
import moment from "moment";

const LoginCallback = ({ setIsLoggedIn, setEmail }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [idTokenBeingFetched, setIdTokenBeingFetched] = useState(false);
  const [performCallback, setPerformCallback] = useState(false);
  const { postData: backendCallback, response: callbackResponse } =
    useLoginCallback();

  const oauthState = Cache.get("OAuthState");

  useEffect(() => {
    const callbackNonceExpiry = Cache.get("CallbackNonceExpiry");
    if (
      !Cache.get("CallbackNonce") ||
      !oauthState ||
      !moment(callbackNonceExpiry, "X", true).isValid() ||
      callbackNonceExpiry < Date.now() / 1000
    ) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (!idTokenBeingFetched && performCallback && oauthState) {
      if (`state-${oauthState}` === searchParams.get("state")) {
        setIdTokenBeingFetched(true);
        setPerformCallback(false);
        const callbackBody = Object.fromEntries(searchParams.entries());
        callbackBody.callbackNonce = Cache.get("CallbackNonce");
        backendCallback(callbackBody);
      }
    } else {
      setPerformCallback(true);
    }
  }, [idTokenBeingFetched, performCallback, oauthState]);

  useEffect(() => {
    if (callbackResponse) {
      Cache.set("email", callbackResponse.email);
      setEmail(callbackResponse.email);
      Cache.set("tokenExpiry", callbackResponse.expiry);
      Cache.remove("OAuthState");
      Cache.remove("CallbackNonce");
      Cache.remove("CallbackNonceExpiry");
      setIsLoggedIn(true);
      navigate(callbackResponse.initialUrl, { replace: true });
    }
  }, [callbackResponse]);
};

export default LoginCallback;
