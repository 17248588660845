/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useListBots, useDeleteUser } from "../../api/users";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import { adaptUsers } from "../../adapters/users";
import { convertUTCToLocalDateTime } from "../../utils/dateUtils";
import useIamModal from "../IamModal/UseIamModal";
import { useCreateBotDialog } from "./dialog/DialogHooks";
import { useHasPermission } from "../../api/iam";

const Bots = ({ displayNotification, setConfirmationDialog }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { IamModal, IamButton } = useIamModal("/user-management/users");

  const [bots, setBots] = useState([]);

  const {
    postData: listBots,
    response: listBotsResp,
    isLoading: isLoadingPrs,
  } = useListBots();

  const {
    postData: deleteUser,
    response: deleteUserResp,
    responseStatus: deleteUserStatus,
  } = useDeleteUser();

  const { createBotDialog, setOpenCreateBotDialog } = useCreateBotDialog(
    listBots,
    displayNotification
  );

  useEffect(() => {
    listBots();
  }, []);

  useEffect(() => {
    if (Array.isArray(listBotsResp?.users)) {
      setBots(adaptUsers(listBotsResp.users));
    }
  }, [listBotsResp]);

  useEffect(() => {
    displayNotification(deleteUserResp, deleteUserStatus, () => {
      listBots();
    });
  }, [deleteUserResp, deleteUserStatus]);

  const handleDelete = (user) => {
    setConfirmationDialog({
      isOpen: true,
      title: "Delete User",
      content: `You are going to delete the user: "${user?.emailId}"`,
      handleDialogConfirm: () => {
        deleteUser(`/${user?.id}`);
      },
    });
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 1.5,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { name } }) => (
        <Typography variant="h5">{name || ""}</Typography>
      ),
    },
    {
      field: "description",
      flex: 3,
      renderHeader: () => <Typography variant="h5">Description</Typography>,
      renderCell: ({ row: { description } }) => (
        <Typography variant="h5">{description ?? ""}</Typography>
      ),
    },
    {
      field: "emailId",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Email</Typography>,
      renderCell: ({ row: { emailId } }) => (
        <Typography variant="h5">{emailId ?? ""}</Typography>
      ),
    },
    {
      field: "createdBy",
      flex: 2,
      renderHeader: () => <Typography variant="h5">createdBy</Typography>,
      renderCell: ({ row: { createdBy } }) => (
        <Typography variant="h5">{createdBy ?? ""}</Typography>
      ),
    },
    {
      field: "createdOn",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Created On</Typography>,
      renderCell: ({ row: { createdOn } }) => (
        <Typography variant="h5">
          {convertUTCToLocalDateTime(createdOn)}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: ({ row }) =>
        [
          <GridActionsCellItem
            label="Delete"
            onClick={() => handleDelete(row)}
            showInMenu
          />,
        ].filter(Boolean),
    },
  ];

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      {IamModal}
      {createBotDialog}
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid item xs={1}>
          <Header title="Bots" subtitle="list of convoy bots" />
        </Grid>
        <Grid item xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            {IamButton}
            {useHasPermission(
              "/user-management/users",
              "POST",
              "CREATE_BOT"
            ) ? (
              <DataGridButton
                sx={dataGridButtonStyles.pageTopButtons}
                onClick={setOpenCreateBotDialog}
              >
                <Typography sx={dataGridButtonTypography(colors, 500)}>
                  CREATE
                </Typography>
              </DataGridButton>
            ) : (
              <></>
            )}
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => listBots()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.id}
          loading={isLoadingPrs}
          rows={bots}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Bots;
