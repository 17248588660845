import { timeFormat } from "../../utils/constants";
import moment from "moment";

export const getDeploymentStage = (tenantUrl) => {
  const msDomain = tenantUrl?.split(".")?.[1];
  switch (msDomain) {
    case "tsl-terls":
      return "dev";
    case "tsl-barc":
      return "qa";
    default:
      return "stage";
  }
};

const complileResults = (results) => {
  return Object.keys(results || {}).reduce((acc, curr) => {
    acc.passed = (acc?.passed || 0) + (results[curr]?.passed || 0);
    acc.failed = (acc?.failed || 0) + results[curr]?.failed || 0;
    acc.skipped = (acc?.skipped || 0) + results[curr]?.skipped || 0;
    return acc;
  }, {});
};

const getWfSuffix = (run) => {
  const arr = run?.argoWfID?.split("-");
  return arr[arr.length - 1];
};

export const adaptAutomationRuns = (runs) => {
  return runs.map((run) => ({
    ...run,
    workflowSuffix: getWfSuffix(run),
    compliedResults: complileResults(run?.resultMetadata?.results),
    startTime: moment(run?.startTime)?.format(timeFormat),
    endTime: moment(run?.endTime)?.format(timeFormat),
  }));
};
