/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import AddUserDialog from "./AddUser";
import CreateBotDialog from "./CreateBot";

export const useAddUserDialog = (
  callback = () => {},
  displayNotification = () => {}
) => {
  const [open, setOpen] = useState(false);
  return {
    addUserDialog: (
      <AddUserDialog
        open={open}
        resetDialog={() => setOpen(false)}
        callback={callback}
        displayNotification={displayNotification}
      />
    ),
    setOpenAddUserDialog: () => setOpen(true),
  };
};

export const useCreateBotDialog = (
  callback = () => {},
  displayNotification = () => {}
) => {
  const [open, setOpen] = useState(false);
  return {
    createBotDialog: (
      <CreateBotDialog
        open={open}
        resetDialog={() => setOpen(false)}
        callback={callback}
        displayNotification={displayNotification}
      />
    ),
    setOpenCreateBotDialog: () => setOpen(true),
  };
};
