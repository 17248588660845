import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import People from "./People";
import Bots from "./Bots";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import { useNotification } from "../../components/Notification";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useHasPermission } from "../../api/iam";

const Users = () => {
  const [selectedTab, setSelectedTab] = useState("People");

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const { notificationComponent, displayNotification } = useNotification();

  const tabs = [
    useHasPermission("/user-management/users", "GET", "LIST_PEOPLE") && {
      value: "People",
      component: (
        <People
          displayNotification={displayNotification}
          setConfirmationDialog={setConfirmationDialog}
        />
      ),
    },
    useHasPermission("/user-management/users", "GET", "LIST_BOTS") && {
      value: "Bots",
      component: (
        <Bots
          displayNotification={displayNotification}
          setConfirmationDialog={setConfirmationDialog}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <Box sx={tabbedDataGridPage.mainBox}>
      {notificationComponent}
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Box sx={tabbedDataGridPage.tabContainer}>
        <TabContext value={selectedTab}>
          <Box sx={tabbedDataGridPage.tabListBox}>
            <TabList
              onChange={(e, newValue) => setSelectedTab(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              sx={tabbedDataGridPage.tabList}
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={i}
                  label={tab.value}
                  value={tab.value}
                  icon={<></>}
                  iconPosition="end"
                />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, i) => (
            <TabPanel key={i} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
};

export default Users;
