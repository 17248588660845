/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { tokens } from "../../theme";
import { Box, Modal, Tab, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Roles from "./Tabs/Roles";
import Groups from "./Tabs/Groups";
import { mainModalStyles as styles } from "./styles";
import CreateDialog from "./Dialogs/CreateDialog";
import Notification from "../../components/Notification";
import { showNotification } from "../../utils/helperFunctions";
import MemebersDialog from "./Dialogs/AssigneesDialog";

const IamModal = ({
  open,
  setOpen,
  path = window.location.pathname,
  canListRoles,
  canListGroups,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(
    canListRoles ? "Roles" : "Groups"
  );
  const [createDialogProps, setCreateDialogProps] = useState({
    isOpen: false,
    entity: "",
    callback: () => {},
  });
  const [assigneesDialogProps, setAssigneesDialogProps] = useState({
    isOpen: false,
    entity: "",
  });

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const notificationStates = {
    setNotify: setNotify,
    setMessage: setMessage,
    setSeverity: setSeverity,
  };

  const showNotificationWrapper = (resp, respStatus, callback = () => {}) => {
    showNotification(
      resp,
      respStatus,
      callback,
      notificationStates.setMessage,
      notificationStates.setSeverity,
      notificationStates.setNotify
    );
  };

  const tabs = [
    canListRoles && {
      value: "Roles",
      component: Roles,
    },
    canListGroups && {
      value: "Groups",
      component: Groups,
    },
  ].filter(Boolean);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.mainBox(colors)}>
          <Notification
            notify={notify}
            severity={severity}
            setNotify={setNotify}
            message={message}
          />
          <CreateDialog
            dialogProps={createDialogProps}
            setDialogProps={setCreateDialogProps}
            path={path}
            showNotificationWrapper={showNotificationWrapper}
          />
          <MemebersDialog
            dialogProps={assigneesDialogProps}
            setDialogProps={setAssigneesDialogProps}
            path={path}
            showNotificationWrapper={showNotificationWrapper}
          />
          <TabContext value={selectedTab}>
            <Box sx={styles.tabListBox}>
              <TabList
                sx={styles.tabList}
                onChange={(e, newValue) => {
                  setSelectedTab(newValue);
                }}
                textColor="secondary"
                indicatorColor="secondary"
              >
                {tabs.map((tab, i) => (
                  <Tab
                    label={tab.value}
                    value={tab.value}
                    icon={<></>}
                    iconPosition="end"
                    key={i}
                    sx={styles.tab}
                  />
                ))}
              </TabList>
            </Box>
            {tabs.map((tab, i) => (
              <TabPanel key={i} value={tab.value}>
                <tab.component
                  path={path}
                  setCreateDialogProps={setCreateDialogProps}
                  setAssigneesDialogProps={setAssigneesDialogProps}
                  showNotificationWrapper={showNotificationWrapper}
                />
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Modal>
    </>
  );
};

export default IamModal;
