import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { tdmUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useListAllControlPlanes = (deploymentStage) => {
  return useLazyFetch(
    `${tdmUrl}/apps/tessell/deployments/${deploymentStage}/control-planes`,
    {
      headers: { ...getHeaders(), Action: "LIST_ALL" },
    }
  );
};
