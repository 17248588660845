/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import DataGridButton from "../../../components/DataGridButton";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { tokens } from "../../../theme";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { useDeleteRole, useListAllRoles } from "../../../api/iam";
import { commonPageStyles } from "../styles";
import { convertUTCToLocalDateTime } from "../../../utils/dateUtils";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { IAM_ENTITY } from "../../../utils/constants";
import PermissionsDialog from "../Dialogs/PermissionsDialog";
import { stackCenterGap } from "../../../styles/utilStyles";

const Roles = ({
  path,
  setCreateDialogProps,
  setAssigneesDialogProps,
  showNotificationWrapper,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [roles, setRoles] = useState([]);

  const [permissionsDialogProps, setPermissionsDialogProps] = useState({
    isOpen: false,
    roleName: "",
  });

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const {
    postData: listAllRoles,
    response: listAllRolesResp,
    isLoading: isLoadingAllRoles,
  } = useListAllRoles(path);

  const {
    postData: deleteRole,
    response: deleteRoleResp,
    responseStatus: deleteRoleStatus,
  } = useDeleteRole(path);

  useEffect(() => {
    listAllRoles();
  }, []);

  useEffect(() => {
    if (Array.isArray(listAllRolesResp?.roles)) {
      setRoles(listAllRolesResp.roles);
    }
  }, [listAllRolesResp]);

  useEffect(() => {
    showNotificationWrapper(deleteRoleResp, deleteRoleStatus, () => {
      listAllRoles();
    });
  }, [deleteRoleResp, deleteRoleStatus]);

  const handleDelete = (role) => {
    const roleName = role?.name;
    setConfirmationDialog({
      isOpen: true,
      title: "Delete Role",
      content: `You are going to delete the role "${roleName}". Are you sure?`,
      handleDialogConfirm: () => {
        deleteRole(`/${roleName}`);
      },
    });
  };

  const handleAssignements = (role) => {
    const roleName = role?.name;
    setAssigneesDialogProps({
      isOpen: true,
      entity: IAM_ENTITY.ROLE,
      entityName: roleName,
    });
  };

  const handlePermissions = (role) => {
    const roleName = role?.name;
    setPermissionsDialogProps({
      isOpen: true,
      roleName: roleName,
    });
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { name } }) => (
        <Typography variant="h5">{name || ""}</Typography>
      ),
    },
    {
      field: "createdOn",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Created On</Typography>,
      renderCell: ({ row: { createdOn } }) => (
        <Typography variant="h5">
          {convertUTCToLocalDateTime(createdOn) ?? ""}
        </Typography>
      ),
    },
    {
      field: "createdBy",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Created By</Typography>,
      renderCell: ({ row: { createdBy } }) => (
        <Typography variant="h5">{createdBy ?? ""}</Typography>
      ),
    },
    {
      field: "forPath",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Path</Typography>,
      renderCell: ({ row: { forPath } }) => (
        <Typography variant="h5">
          .{forPath === path ? "" : forPath?.slice(path?.length)}
        </Typography>
      ),
    },
    {
      field: "description",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Description</Typography>,
      renderCell: ({ row: { description } }) => (
        <Typography variant="h5">{description}</Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: ({ row }) =>
        [
          <GridActionsCellItem
            label="Assignemnts"
            onClick={() => handleAssignements(row)}
            showInMenu
          />,
          <GridActionsCellItem
            label="Permissions"
            onClick={() => handlePermissions(row)}
            showInMenu
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={() => handleDelete(row)}
            showInMenu
          />,
        ].filter(Boolean),
    },
  ].filter(Boolean);

  return (
    <Box sx={commonPageStyles.tabContentMainBox}>
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <PermissionsDialog
        dialogProps={permissionsDialogProps}
        setDialogProps={setPermissionsDialogProps}
        path={path}
        showNotificationWrapper={showNotificationWrapper}
      />
      <Box sx={commonPageStyles.topButtonStack}>
        <DataGridButton
          sx={commonPageStyles.pageTopButtons}
          onClick={() =>
            setCreateDialogProps({
              isOpen: true,
              entity: IAM_ENTITY.ROLE,
              callback: listAllRoles,
            })
          }
        >
          <Typography sx={dataGridButtonTypography(colors, 500)}>
            CREATE
          </Typography>
        </DataGridButton>
        <DataGridButton
          sx={commonPageStyles.pageTopButtons}
          onClick={() => listAllRoles()}
        >
          <Typography sx={dataGridButtonTypography(colors, 500)}>
            RELOAD
          </Typography>
        </DataGridButton>
        <Box sx={stackCenterGap} />
        <Typography variant="h5" sx={commonPageStyles.pathTypography(colors)}>
          Path: {path}
        </Typography>
      </Box>
      <Box sx={commonPageStyles.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.id}
          loading={isLoadingAllRoles}
          rows={roles}
          columns={columns}
          slots={{
            loadingOverlay: LoadingOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default Roles;
