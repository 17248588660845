/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { ControlPointRounded } from "@mui/icons-material";
import { permissionsDialogStyles as styles } from "../styles";
import { stackCenterGap } from "../../../../styles/utilStyles";
import { TextFieldWithHelperText } from "../../../../components/TextFieldWithHelperText";
import PermissionItem from "./PermissionItem";

const isPermissionEqual = (p1, p2) => {
  if (p1?.action !== p2?.action) {
    return false;
  } else if (p1?.method !== p2?.method) {
    return false;
  } else if (p1?.forAllSubPaths !== p2?.forAllSubPaths) {
    return false;
  } else if (!p1?.iam && !p2?.iam) {
    return true;
  } else if (!p1?.iam || !p2?.iam) {
    return false;
  } else if (p1?.iam?.path !== p2?.iam?.path) {
    return false;
  } else if (p1?.iam?.forAllSubPaths !== p2?.iam?.forAllSubPaths) {
    return false;
  }
  return true;
};

const isAddPermissionButtonEnabled = (permState) => {
  const iamPath = permState?.iam?.path;
  if (
    iamPath !== undefined &&
    !(iamPath === "/_iam_") &&
    !iamPath.startsWith("/_iam_/")
  ) {
    return false;
  }
  return permState?.method && permState?.action;
};

const PermissionsList = ({
  currPermissions,
  setCurrPermissions,
  canUpdatePermissions,
}) => {
  const [newPermission, setNewPermission] = useState({});

  const handleAddPermission = (e) => {
    if (
      currPermissions.some((item) => isPermissionEqual(item, newPermission))
    ) {
      return;
    }
    setCurrPermissions((prev) => [...prev, newPermission]);
    setNewPermission({});
  };

  const deletePermission = (perm) => {
    setCurrPermissions((prev) =>
      prev.filter((item) => !isPermissionEqual(perm, item))
    );
  };

  return (
    <Box sx={styles.permissionsStack}>
      <Typography variant="h5">Permissions</Typography>
      <Divider />
      <Virtuoso
        data={currPermissions}
        components={PermissionItem}
        itemContent={(index, perm) => (
          <PermissionItem
            perm={perm}
            deletePermission={deletePermission}
            canUpdatePermissions={canUpdatePermissions}
          />
        )}
      />
      {canUpdatePermissions ? (
        <Box>
          <FormGroup sx={styles.inputRowStack}>
            <Select
              displayEmpty
              renderValue={(value) => {
                if (!newPermission?.method) {
                  return <Typography color="gray">Method</Typography>;
                }
                return newPermission?.method;
              }}
              value={newPermission?.method || ""}
              sx={styles.methodField}
              size="small"
              onChange={(e) =>
                setNewPermission((prev) => ({
                  ...prev,
                  method: e.target.value,
                }))
              }
            >
              {["GET", "DELETE", "PATCH", "POST", "PUT", "*"].map((view, i) => (
                <MenuItem value={view} key={i}>
                  {view}
                </MenuItem>
              ))}
            </Select>
            <TextField
              sx={styles.actionField}
              placeholder="ACTION"
              hiddenLabel
              size="small"
              value={newPermission?.action || ""}
              onChange={(event) => {
                setNewPermission((prev) => ({
                  ...prev,
                  action: event.target.value,
                }));
              }}
            />
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label="For all sub-paths"
              checked={newPermission?.forAllSubPaths || false}
              onChange={(e) => {
                setNewPermission((prev) => ({
                  ...prev,
                  forAllSubPaths: e.target.checked,
                }));
              }}
            />

            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label="On IAM"
              checked={newPermission?.iam !== undefined}
              onChange={(e) => {
                setNewPermission((prev) => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      iam: {
                        path: "/_iam_",
                      },
                    };
                  }
                  const { iam, ...rest } = prev;
                  return rest;
                });
              }}
            />
            <Box sx={stackCenterGap} />
            <IconButton
              disabled={!isAddPermissionButtonEnabled(newPermission)}
              flex="1"
              onClick={(event) => handleAddPermission(event)}
            >
              <ControlPointRounded />
            </IconButton>
          </FormGroup>
          {newPermission?.iam ? (
            <FormGroup sx={styles.inputRowStack}>
              <TextFieldWithHelperText
                sx={styles.iamPathTextField}
                label="IAM Path"
                helperText="must start with '/_iam_'"
                color="secondary"
                size="small"
                value={newPermission?.iam?.path || ""}
                onChange={(event) => {
                  setNewPermission((prev) => ({
                    ...prev,
                    iam: {
                      ...prev?.iam,
                      path: event.target.value,
                    },
                  }));
                }}
              />
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                label="For all IAM sub-paths"
                checked={newPermission?.iam?.forAllSubPaths || false}
                onChange={(e) => {
                  setNewPermission((prev) => ({
                    ...prev,
                    iam: {
                      ...prev?.iam,
                      forAllSubPaths: e.target.checked,
                    },
                  }));
                }}
              />
            </FormGroup>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PermissionsList;
