/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import IamModal from "./IamModal";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import DataGridButton from "../../components/DataGridButton";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useHasPermission } from "../../api/iam";

const useIamModal = (
  path = window.location.pathname,
  dataGridButtonSx = dataGridButtonStyles.pageTopButtons
) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const canListRoles = useHasPermission(
    `${path}/_iam_/roles`,
    "GET",
    "LIST_ALL"
  );
  const canListGroups = useHasPermission(
    `${path}/_iam_/groups`,
    "GET",
    "LIST_ALL"
  );

  return {
    IamModal: (
      <IamModal
        open={open}
        setOpen={setOpen}
        path={path}
        canListGroups={canListGroups}
        canListRoles={canListRoles}
      />
    ),
    IamButton:
      canListGroups || canListRoles ? (
        <DataGridButton sx={dataGridButtonSx} onClick={() => setOpen(true)}>
          <Typography sx={dataGridButtonTypography(colors, 500)}>
            IAM
          </Typography>
        </DataGridButton>
      ) : (
        <></>
      ),
    openIamModal: () => setOpen(true),
    canViewIamModal: canListGroups || canListRoles,
  };
};

export default useIamModal;
