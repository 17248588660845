import moment from "moment";

export const convertUTCTimeToLocalTime = (scheduleInUTC) => {
  // Create a moment object with the time in UTC
  const scheduleInUTCTime = moment.utc(scheduleInUTC, "HH:mm");

  // Convert the time to the local time of the browser
  const scheduleInLocalTime = scheduleInUTCTime.local();

  // Format the output to display the time in local time
  const scheduleInLocalFormat = scheduleInLocalTime.format("HH:mm");
  return scheduleInLocalFormat;
};

export const convertUTCToLocalDateTime = (
  input,
  outputFormat = "D MMM YYYY, h:mm:ss a"
) => {
  const localTimeObject = moment.utc(input).local();
  return localTimeObject.format(outputFormat);
};

export const convertLocalTimeToUTCTime = (scheduleInLocalTime) => {
  const localMoment = moment(scheduleInLocalTime, "HH:mm");

  // Convert the local time to UTC
  const utcTime = localMoment.utc();

  // Format the output to display the time in UTC
  return utcTime.format("HH:mm");
};

// Create a function to get the time zone abbreviation
export const getTimeZoneAbbreviation = () => {
  const abbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
  return abbreviation;
};
