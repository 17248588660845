import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { iamUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useAddUser = () => {
  return useLazyPost(`${iamUrl}/user-management/users`, {
    headers: { ...getHeaders(), Action: "ADD" },
    method: "POST",
  });
};

export const useCreateBot = () => {
  return useLazyPost(`${iamUrl}/user-management/users`, {
    headers: { ...getHeaders(), Action: "CREATE_BOT" },
    method: "POST",
  });
};

export const useDeleteUser = () => {
  return useLazyFetch(`${iamUrl}/user-management/users`, {
    headers: { ...getHeaders(), Action: "DELETE" },
    method: "DELETE",
  });
};

export const useListPeople = () => {
  return useLazyFetch(`${iamUrl}/user-management/users`, {
    headers: { ...getHeaders(), Action: "LIST_PEOPLE" },
  });
};

export const useListBots = () => {
  return useLazyFetch(`${iamUrl}/user-management/users`, {
    headers: { ...getHeaders(), Action: "LIST_BOTS" },
  });
};
