/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
import { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import PropTypes from "prop-types";
import { showNotification } from "../utils/helperFunctions";

const Notification = ({ message, notify, setNotify, severity, onClose }) => {
  const [duration, setDuration] = useState(6000);

  useEffect(() => {
    if (notify) {
      if (message?.length > 100) {
        setDuration(10000);
      } else {
        setDuration(5000);
      }
    }
  }, [notify, message]);

  return message?.length ? (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={notify}
      autoHideDuration={duration}
      onClose={(ev, reason) => {
        if (reason === "timeout" || reason === "escapeKeyDown") {
          onClose?.({ message, severity });
          setNotify(false);
        }
      }}
      key={message}
    >
      <Alert
        severity={severity}
        onClose={() => {
          onClose?.({ message, severity });
          setNotify(false);
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : null;
};

Notification.propTypes = {
  notify: PropTypes.bool.isRequired,
  setNotify: PropTypes.func.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string,
};

Notification.defaultProps = {
  severity: "info",
};

export default Notification;

export const useNotification = () => {
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const displayNotification = (resp, respStatus, callback = () => {}) => {
    showNotification(
      resp,
      respStatus,
      callback,
      setMessage,
      setSeverity,
      setNotify
    );
  };

  return {
    notificationComponent: (
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
    ),
    displayNotification,
  };
};
